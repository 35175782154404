





































import Vue, { VueConstructor } from 'vue';
// import ValidatedRadio from '@/components/forms/ValidatedRadio.vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';
import HelpCard from '@/components/shared/HelpCard.vue';

class PlayroomEditProductVariantPartViewModel {
  id!: string;
  productVariant!: string;
  possibleProductVariants!: string[];
}

const validations = {
  playroom: {
    productVariant: {
      required,
    },
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { ValidatedFormCard, HelpCard },
  mixins: [validationMixin, HelpCardMixin],
  validations: validations,
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditProductVariantPartViewModel,
      ready: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditProductVariantPart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditProductVariantPartViewModel;
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    onFocusChange(newFocus: string) {
      switch (newFocus) {
        case 'productVariant':
        case 'availableUntil': {
          this.focusFor = 'components.playroomEditParts.ProductVariantPart.info.box1';
          break;
        }
        default: {
          this.focusFor = '';
        }
      }
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var response = await OwnerPlayroomService.saveProductVariantPart(this.playroomId, this.playroom);
        return response.success;
      } else {
        return false;
      }
    },
  },
});
