







































import Vue from 'vue';
import ValidatedRadioGroup from '@/components/forms/ValidatedRadioGroup.vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';

class PlayroomEditAdvancedBookingPartViewModel {
  id!: string;
  bookingMaxDaysInAdvance!: number;
  bookingMinHoursInAdvance!: number;
}

const validations = {
  playroom: {
    bookingMaxDaysInAdvance: {
      required,
    },
    bookingMinHoursInAdvance: {
      required,
    },
  },
};

export default Vue.extend({
  components: { ValidatedFormCard, ValidatedRadioGroup },
  mixins: [validationMixin],
  validations: validations,
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditAdvancedBookingPartViewModel,
      ready: false,
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    bookingMaxDaysInAdvanceOptions(): { value: string; label: string }[] {
      return [
        { value: '7', label: this.$t('components.playroomEditParts.AdvancedBookingPart.maxDays.7Days').toString() },
        { value: '30', label: this.$t('components.playroomEditParts.AdvancedBookingPart.maxDays.30Days').toString() },
        { value: '90', label: this.$t('components.playroomEditParts.AdvancedBookingPart.maxDays.90Days').toString() },
        { value: '180', label: this.$t('components.playroomEditParts.AdvancedBookingPart.maxDays.180Days').toString() },
        { value: '365', label: this.$t('components.playroomEditParts.AdvancedBookingPart.maxDays.365Days').toString() },
        { value: '548', label: this.$t('components.playroomEditParts.AdvancedBookingPart.maxDays.548Days').toString() },
      ];
    },

    bookingMinHoursInAdvanceOptions(): { value: string; label: string }[] {
      return [
        { value: '0', label: this.$t('components.playroomEditParts.AdvancedBookingPart.minHours.None').toString() },
        { value: '3', label: this.$t('components.playroomEditParts.AdvancedBookingPart.minHours.3Hours').toString() },
        { value: '24', label: this.$t('components.playroomEditParts.AdvancedBookingPart.minHours.24Hours').toString() },
        { value: '72', label: this.$t('components.playroomEditParts.AdvancedBookingPart.minHours.72Hours').toString() },
        {
          value: '168',
          label: this.$t('components.playroomEditParts.AdvancedBookingPart.minHours.168Hours').toString(),
        },
      ];
    },
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditAdvancedBookingPart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditAdvancedBookingPartViewModel;
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var response = await OwnerPlayroomService.saveAdvancedBookingPart(this.playroomId, { ...this.playroom });
        if (response.success) {
          this.$emit('part-save-status-type-change', PartSaveStatusType.Saved);
          this.$v.$reset();
        }
        return response.success;
      } else {
        return false;
      }
    },
  },
});
