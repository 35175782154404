




















































import Vue, { VueConstructor } from 'vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import { validationMixin } from 'vuelidate';
import { helpers } from 'vuelidate/lib/validators';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';
import HelpCard from '@/components/shared/HelpCard.vue';
import { isAfter, isBefore, isSameMinute, parse } from 'date-fns';

const afterAvailableFrom = helpers.withParams(
  { type: 'minDate' },
  (value: string, object: { availableFrom: string }) => {
    if (!object.availableFrom || !value) {
      return true;
    }
    const availableUntil = parse(value, 'yyyy-MM-dd', new Date());
    const availableFrom = parse(object.availableFrom, 'yyyy-MM-dd', new Date());
    return isSameMinute(availableUntil, availableFrom) || isAfter(availableUntil, availableFrom);
  }
);

const beforeAvailableUntil = helpers.withParams(
  { type: 'minDate' },
  (value: string, object: { availableUntil: string }) => {
    if (!object.availableUntil || !value) {
      return true;
    }
    const availableFrom = parse(value, 'yyyy-MM-dd', new Date());
    const availableUntil = parse(object.availableUntil, 'yyyy-MM-dd', new Date());
    return isSameMinute(availableUntil, availableFrom) || isBefore(availableFrom, availableUntil);
  }
);

class PlayroomEditTemporalAvailabilityPartViewModel {
  id!: string;
  availableUntil!: string;
  alwaysAvailable!: boolean;
  availableFrom!: string;
}

const validations = {
  playroom: {
    availableUntil: {
      afterAvailableFrom: function (value: string, object: { availableFrom: string }) {
        const alwaysAvailable = ((this as any).playroom as PlayroomEditTemporalAvailabilityPartViewModel)
          .alwaysAvailable;
        if (alwaysAvailable) {
          return true;
        }
        return afterAvailableFrom(value, object);
      },
    },
    alwaysAvailable: {},
    availableFrom: {
      beforeAvailableUntil: function (value: string, object: { availableUntil: string }) {
        const alwaysAvailable = ((this as any).playroom as PlayroomEditTemporalAvailabilityPartViewModel)
          .alwaysAvailable;
        if (alwaysAvailable) {
          return true;
        }
        return beforeAvailableUntil(value, object);
      },
    },
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { ValidatedInput, ValidatedFormCard, ValidatedCheckbox, HelpCard },
  mixins: [validationMixin, HelpCardMixin],
  validations: validations,
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditTemporalAvailabilityPartViewModel,
      ready: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditTemporalAvailabilityPart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditTemporalAvailabilityPartViewModel;
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    onFocusChange(newFocus: string) {
      switch (newFocus) {
        case 'availableFrom':
        case 'availableUntil': {
          this.focusFor = 'components.playroomEditParts.TemporalAvailabiltyPart.info.box1';
          break;
        }
        default: {
          this.focusFor = '';
        }
      }
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var response = await OwnerPlayroomService.saveTemporalAvailabilityPart(this.playroomId, { ...this.playroom });
        this.loadData();
        return response.success;
      } else {
        return false;
      }
    },

    checkAlwaysAvailable: function () {
      if (this.playroom.alwaysAvailable) {
        this.playroom.availableUntil = new Date(2999, 12, 31).toISOString().split('T')[0];
      } else {
        const date = new Date();
        date.setMonth(date.getMonth() + 1);
        this.playroom.availableUntil = date.toISOString().split('T')[0];
      }
    },
  },
});
