























































































































































































































































import Vue, { VueConstructor } from 'vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import { validationMixin } from 'vuelidate';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';
import { de } from 'date-fns/locale';
import startOfToday from 'date-fns/startOfToday';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';

class PlayroomEditBookingTimeRestrictionsPartViewModel {
  id!: string;
  allowOvernightBooking!: boolean;
  allowHourBooking!: boolean;
  overnightCheckInStartAt!: number | string;
  overnightCheckInEndAt!: number | string;
  hourCheckInStartAt!: number | string;
  hourCheckInEndAt!: number | string;
  minutesForCleaning!: number;
  minimalBookingHours!: number;
  minimalBookingNights!: number;
  overnightCheckOutStartAt!: number | string;
  overnightCheckOutEndAt!: number | string;
  allowedDaysForHourlyBooking!: AllowedDaysDto;
  allowedDaysForHourlyCheckIn!: AllowedDaysDto;
  allowedDaysForHourlyCheckOut!: AllowedDaysDto;
  allowedDaysForOvernightBooking!: AllowedDaysDto;
  allowedDaysForOvernightCheckIn!: AllowedDaysDto;
  allowedDaysForOvernightCheckOut!: AllowedDaysDto;
}

export class AllowedDaysDto {
  onMonday!: boolean;
  onTuesday!: boolean;
  onWednesday!: boolean;
  onThursday!: boolean;
  onFriday!: boolean;
  onSaturday!: boolean;
  onSunday!: boolean;
  beforeHoliday!: boolean;
  onHoliday!: boolean;
}

const validations = {
  playroom: {
    allowOvernightBooking: {},
    allowHourBooking: {},
    overnightCheckInStartAt: {},
    overnightCheckInEndAt: {},
    hourCheckInStartAt: {},
    hourCheckInEndAt: {},
    minutesForCleaning: {},
    minimalBookingHours: {},
    minimalBookingNights: {},
    overnightCheckOutStartAt: {},
    overnightCheckOutEndAt: {},
    allowedDaysForHourlyBooking: {
      onMonday: {},
      onTuesday: {},
      onWednesday: {},
      onThursday: {},
      onFriday: {},
      onSaturday: {},
      onSunday: {},
      beforeHoliday: {},
      onHoliday: {},
    },
    allowedDaysForHourlyCheckIn: {
      onMonday: {},
      onTuesday: {},
      onWednesday: {},
      onThursday: {},
      onFriday: {},
      onSaturday: {},
      onSunday: {},
      beforeHoliday: {},
      onHoliday: {},
    },
    allowedDaysForHourlyCheckOut: {
      onMonday: {},
      onTuesday: {},
      onWednesday: {},
      onThursday: {},
      onFriday: {},
      onSaturday: {},
      onSunday: {},
      beforeHoliday: {},
      onHoliday: {},
    },
    allowedDaysForOvernightBooking: {
      onMonday: {},
      onTuesday: {},
      onWednesday: {},
      onThursday: {},
      onFriday: {},
      onSaturday: {},
      onSunday: {},
      beforeHoliday: {},
      onHoliday: {},
    },
    allowedDaysForOvernightCheckIn: {
      onMonday: {},
      onTuesday: {},
      onWednesday: {},
      onThursday: {},
      onFriday: {},
      onSaturday: {},
      onSunday: {},
      beforeHoliday: {},
      onHoliday: {},
    },
    allowedDaysForOvernightCheckOut: {
      onMonday: {},
      onTuesday: {},
      onWednesday: {},
      onThursday: {},
      onFriday: {},
      onSaturday: {},
      onSunday: {},
      beforeHoliday: {},
      onHoliday: {},
    },
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { ValidatedFormCard, ValidatedCheckbox, ValidatedSelect },
  mixins: [validationMixin, HelpCardMixin],
  validations() {
    var vals = { ...validations };
    if (!this.playroom.allowOvernightBooking && !this.playroom.allowHourBooking) {
      vals.playroom.allowOvernightBooking = { checkedOvernightOrHourly: (val: boolean) => val || false };
      vals.playroom.allowHourBooking = { checkedOvernightOrHourly: (val: boolean) => val || false };
    } else {
      vals.playroom.allowOvernightBooking = {};
      vals.playroom.allowHourBooking = {};
    }
    return vals;
  },
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditBookingTimeRestrictionsPartViewModel,
      ready: false,
      checkInToTimes: [] as { label: string; value: number }[],
      checkOutToTimes: [] as { label: string; value: number }[],
      isCheckInToLoaded: false,
      isCheckOutToLoaded: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.ready = false;
      OwnerPlayroomService.getForEditBookingTimeRestrictionsPart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditBookingTimeRestrictionsPartViewModel;
        this.ready = true;
        this.isCheckInToLoaded = true;
        this.isCheckOutToLoaded = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.ready = false;
        var response = await OwnerPlayroomService.saveBookingTimeRestrictionsPart(this.playroomId, {
          ...this.playroom,
        });
        if (response.success) {
          this.loadData();
          this.$emit('part-save-status-type-change', PartSaveStatusType.Saved);
        }
        return response.success;
      } else {
        return false;
      }
    },
    onFocusChange(name: string): void {
      switch (name) {
        case 'minimalBookingHours':
        case 'minimalBookingNights':
        case 'hourCheckInStartAt':
        case 'hourCheckInEndAt':
        case 'overnightCheckInStartAt':
        case 'overnightCheckInEndAt':
        case 'overnightCheckOutStartAt':
        case 'overnightCheckOutEndAt': {
          this.focusFor = 'components.playroomEditParts.BookingTimeRestrictionsPart.info.box1';
          break;
        }
        case 'minutesForCleaning': {
          this.focusFor = 'components.playroomEditParts.BookingTimeRestrictionsPart.info.box2';
          break;
        }
        default: {
          this.focusFor = '';
        }
      }
    },
    overnightCheckInEndAtOptions() {
      this.checkInToTimes = [];
      let hourStartAt = parseInt(this.playroom.overnightCheckInStartAt as string) || 0;
      hourStartAt = hourStartAt / 60 + 1;
      this.checkInToTimes = this.checkInOutOptions(hourStartAt, 24);
      if (this.isCheckInToLoaded == false) {
        this.playroom.overnightCheckInEndAt = this.checkInToTimes[0].value;
      }
      this.isCheckInToLoaded = false;
    },
    overnightCheckOutEndAtOptions() {
      this.checkOutToTimes = [];
      let hourStartAt = parseInt(this.playroom.overnightCheckOutStartAt as string) || 0;
      hourStartAt = hourStartAt / 60 + 1;
      this.checkOutToTimes = this.checkInOutOptions(hourStartAt, 24);
      if (this.isCheckOutToLoaded == false) {
        this.playroom.overnightCheckOutEndAt = this.checkOutToTimes[0].value;
      }
      this.isCheckOutToLoaded = false;
    },
    checkInOutOptions(hourStart: number, hourEnd: number) {
      let options = [];
      var date = startOfToday();
      for (let i = hourStart; i < hourEnd; i++) {
        let minsFromMidnight = i * 60;
        let newDate = addMinutes(date, minsFromMidnight);
        let label = format(newDate, 'p', { locale: de });
        options.push({ label, value: minsFromMidnight });
        minsFromMidnight += 30;
        newDate = addMinutes(date, minsFromMidnight);
        label = format(newDate, 'p', { locale: de });
        options.push({ label, value: minsFromMidnight });
      }
      return options;
    },
  },
  watch: {
    playroom: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.playroom.allowHourBooking) {
          this.playroom.minimalBookingHours = 0;
        } else {
          if (this.playroom.minimalBookingHours == 0) {
            this.playroom.minimalBookingHours = 1;
          }
        }
        if (!this.playroom.allowOvernightBooking) {
          this.playroom.minimalBookingNights = 0;
        } else {
          if (this.playroom.minimalBookingNights == 0) {
            this.playroom.minimalBookingNights = 1;
          }
        }
        if (this.maxBookingTime < this.playroom.minimalBookingHours) {
          this.playroom.minimalBookingHours = this.maxBookingTime;
        }
      },
    },
    overnightCheckInStartAt: {
      immediate: true,
      deep: true,
      handler() {
        this.overnightCheckInEndAtOptions();
      },
    },
    overnightCheckOutStartAt: {
      immediate: true,
      handler() {
        this.overnightCheckOutEndAtOptions();
      },
    },
  },
  computed: {
    overnightCheckInStartAt(): number | string {
      return this.playroom.overnightCheckInStartAt;
    },
    overnightCheckOutStartAt(): number | string {
      return this.playroom.overnightCheckOutStartAt;
    },
    maxBookingTime(): number {
      return (Number(this.playroom.hourCheckInEndAt) - Number(this.playroom.hourCheckInStartAt)) / 60;
    },
    overnightCheckInStartAtOptions(): { label: string; value: number }[] {
      return this.checkInOutOptions(0, 24);
    },
    overnightCheckOutStartAtOptions(): { label: string; value: number }[] {
      return this.checkInOutOptions(0, 24);
    },
    hourCheckInStartAtOptions(): { label: string; value: number }[] {
      var options = [];
      var date = startOfToday();
      for (var i = 0; i <= 24; i++) {
        const minsFromMidnight = i * 60;
        const newDate = addMinutes(date, minsFromMidnight);
        const label = format(newDate, 'p', { locale: de });
        options.push({ label, value: minsFromMidnight });
      }
      return options;
    },

    hourCheckInEndAtOptions(): { label: string; value: number }[] {
      var options = [];
      const hourCheckInStartAt = parseInt(this.playroom.hourCheckInStartAt as string) || 0;
      const startOfCheckIn = addMinutes(startOfToday(), hourCheckInStartAt);
      const nextDayTranslation = this.$t('components.playroomEditParts.BookingTimeRestrictionsPart.nextDay');
      for (var i = 1; i <= 24; i++) {
        const minsFromStartOfCheckIn = i * 60;
        const newDate = addMinutes(startOfCheckIn, minsFromStartOfCheckIn);
        var label = format(newDate, 'p', { locale: de });
        const mins = minsFromStartOfCheckIn + hourCheckInStartAt;
        if (mins / 60 > 24) {
          label += ' (' + nextDayTranslation + ')';
        }
        if (label == '00:00') {
          label = '24:00';
        }
        options.push({ label, value: hourCheckInStartAt + minsFromStartOfCheckIn });
      }
      return options;
    },

    minimalBookingHoursOptions(): { label: number; value: number }[] {
      var options = [];
      for (var i = 1; i <= this.maxBookingTime; i++) {
        options.push({ label: i, value: i });
      }
      return options;
    },

    minimalBookingNightsOptions(): { label: number; value: number }[] {
      var options = [];
      for (var i = 1; i <= 7; i++) {
        options.push({ label: i, value: i });
      }
      return options;
    },

    minutesForCleaningOptions(): { label: number; value: number }[] {
      var options = [];
      for (var i = 0; i <= 6.5; i += 0.5) {
        options.push({ label: i, value: i * 60 });
      }
      return options;
    },
  },
});
